<script lang="ts" setup>
import type { User } from "~/models/User"

definePageMeta({
    middleware: ["sanctum:auth"],
})

const canInStore = useCanInStore()
const canPermissions = useCan()
const currentStoreStore = useCurrentStoreStore()

const props = withDefaults(
    defineProps<{
        permissions: string[]
        environment?: "store" | "marketplace" | "both"
    }>(),
    {
        environment: "store",
    }
)

const { user } = useSanctumAuth<User>()

const can = computed(() => {
    if (props.environment == "store") {
        if (currentStoreStore.store)
            return canInStore(props.permissions, currentStoreStore.store.id)
    }

    if (props.environment == "marketplace") {
        return canPermissions(props.permissions, "marketplace")
    }

    return canPermissions(props.permissions)
})
</script>
<template>
    <NuxtLayout name="admin-main-layout">
        <div
            :class="`tw-flex tw-text-center tw-justify-center tw-items-center ${!can ? 'tw-min-h-[60vh]' : 'tw-min-h-[200px]'} tw-w-full`"
        >
            <p
                v-if="
                    user?.all_permissions.length == 0 &&
                    currentStoreStore.store == undefined
                "
                class="text-center tw-w-full"
            >
                {{ $t("stores.select_store_in_toolbar") }}
            </p>
            <p v-else-if="!can">{{ $t("stores.unauthorize") }}</p>
            <div v-else class="tw-w-full tw-self-start">
                <div class="tw-flex tw-items-center tw-gap-4 tw-p-4 tw-text-xl">
                    <slot name="header"></slot>
                </div>
                <slot></slot>
            </div>
        </div>
    </NuxtLayout>
</template>
